export const locale = {
    lang: `en`,
    data: {
        asset_group_grid: {
            search_field: `Search Records`,
            heading: `Asset Groups`,
            summary_heading: `Summary`,
            summary_asset_group: `Asset Group`,
            add_asset_group: `Add Asset Group`,
            filters: `Filter`,
            no_records: `No Records.`,
            column: {
                asset_group: `Asset Group`,
                depreciation_method: `Depreciation Method`,
                asset_account: `Asset Account`,
                depreciation_account: `Accumulated Depreciation Acct.`,
                expense_account: `Depreciation Expense Acct.`,
                status: `Status`,
                status_activate: `Activate`,
                status_deactivate: `Deactivate`,
            },
            row_operation: {
                edit: `Edit`,
                copy: `Copy`,
                activate: `Activate`,
                deactivate: `Deactivate`,
                delete: `Delete`,
            },
            messages: {
                try_again: `Please try again!`,
                status: (obj) => `Asset Group marked as ${obj.status ? 'Active' : 'Inactive'} successfully.`,
                success_delete: `Asset Group deleted successfully.`,
            },
            dialog: {
                delete: {
                title: `Delete Asset Group`,
                body: `Are you sure you want to delete this Asset Group? <br> This action cannot be undone.`,
                primary_btn: `Delete`,
                secondary_btn: `Do not delete`,
                },
                deactivate: {
                title: `Deactivate Asset Group`,
                body: `Are you sure you want to deactivate this Asset Group?<br>
                                You will not be able to assign new Fixed Assets to this group.<br>
                                Your existing Fixed Assets will remain unchanged.`,
                primary_btn: `Deactivate`,
                secondary_btn: `Do not deactivate`,
                },
                warning: {
                    title: `Unable to perform action`,
                    body: `You cannot edit or delete this Asset Group because it is linked to some Assets.`,
                    primary_btn: `Got it`,
                    },
            },
        },
        fixed_asset_grid: {
            search_field: `Search Records`,
            heading: `Fixed Asset`,
            summary_heading: `Summary`,
            summary_fixed_asset: `Fixed Asset`,
            summary_net_fixed_asset: `Net Fixed Asset`,
            add_fixed_asset: `Add Fixed Asset`,
            filter: `Filter`,
            no_records: `No Records.`,
            column: {
                number: `Number`,
                asset_name: `Asset Name`,
                asset_group: `Asset Group`,
                remaining_life: `Remaining Life`,
                purchase_date: `Purchase Date`,
                book_value: `Book Value`,
                net_book_value: `Net Book value`,
            },
            row_operation: {
                edit: `Edit`,
                copy: `Copy`,
                delete: `Delete`,
            },
            messages: {
                could_not_fetch_expense_bill: `Could not fetch expense bill info to delete.`,
                could_not_delete_expense_bill: `Could not delete expense bill for asset.`,
                try_again: `Please try again!`,
                success_delete: `Asset deleted successfully.`,
            },
            dialog: {
                delete: {
                title: `Delete Fixed Asset`,
                body: `Are you sure you want to delete this asset? <br> This action cannot be undone.`,
                primary_btn: `Delete`,
                secondary_btn: `Do not delete`,
                },
                warning: {
                    title: `Unable to perform action`,
                    body: `You cannot edit or delete this Asset because there is Depreciation JE
                        linked to it or Asset Acquisition Bill has been paid.`,
                    primary_btn: `Got it`,
                    },
            },
        },
        asset_group_form: {
            btn_add: (obj) => `${obj.isUpdate ? 'Update' : 'Add'} Asset Group`,
            form_heading: (obj) => `${obj.isUpdate ? 'Edit' : obj.isCopy ? 'Copy' : 'Add New'} Asset Group`,
            tab1: {
                title: `Asset Group Details`,
                sub_head: `Asset Group Details`,
                group_name: `Asset Group Name`,
                asset_account: `Asset Account`,
                depreciation_account: `Accumulated Depreciation Account`,
                expense_account: `Depreciation Expense Account`,
            },
            tab2: {
                title: `Accounting`,
                sub_head: `Accounting`,
                depreciation_method: `Depreciation Method`,
                convention: `Convention`,
                rate: `Rate (%)`,
                effective_life: `Effective Life (Years)`,
                depreciation_factor: `Depreciation Factor`,
            },
            messages: {
                group_exists: `Asset Group with same name already exists.`,
                try_again: `Please try again later!`,
                fill_required: `Please fill all required fields.`,
                group_added: (obj) => `Asset Group ${obj.isUpdate ? 'Updated' : 'Added'} successfully.`,
            },
        },
        fixed_asset_form: {
            btn_add: (obj) => `${obj.isUpdate ? 'Update' : 'Add'} Asset`,
            form_heading: (obj) => `${obj.isUpdate ? 'Edit' : obj.isCopy ? 'Copy' : 'Add'} Asset`,
            tab1: {
                title: `Asset Details`,
                sub_head: `Asset Details`,
                this_is_opening_asset: `This is an opening asset`,
                name: `Asset Name`,
                serial_number: `Serial Number`,
                purchase_date: `Purchase date`,
                auto_numbering_format: `Asset Auto-Numbering Format`,
                purchase_price: `Purchase Price`,
                contact: `Contact`,
                bill_auto_numbering_format: `Bill Auto-Numbering Format`,
                opening_accumulated_depreciation: `Opening Accumulated Depreciation`,
                description: `Description`,
                bill_details: `Bill Details`,
                bill_date: `Bill date`,
                due_date: `Due date`,
                book_beginning_date: `Book Beginning Date`
,            },
            tab2: {
                title: `Accounting`,
                sub_head: `Accounting`,
                asset_group: `Asset Group Name`,
                depreciation_start_date: `Depreciation Start Date`,
                depreciation_threshold: `Depreciation Threshold`,
                residual_value: `Residual Value`,
                depreciation_method: `Depreciation Method`,
                convention: `Convention`,
                rate: `Rate (%)`,
                effective_life: `Effective Life (Years)`,
                depreciation_factor: `Depreciation Factor`,
            },
            messages: {
                try_again: `Please try again later!`,
                update_billing_shipping_addresses_in_books: `Kindly update your company billing & shipping address in Books before creating a bill.`,
                gstin_required_for_contact: "Add GSTIN for contact, in Books.",
                expense_bill_save_error: `Expense Bill could not be saved. Please try again later.`,
                expense_bill_get_error: `Expense Bill info could not be fetched. Please try again later.`,
                fill_required: `Please fill all required fields.`,
                asset_added: (obj) => `Fixed Asset  ${obj.isUpdate ? 'Updated' : 'Added'} successfully.`,
                delete_error_format: `Can not delete format as, it is being used.`,
                delete_success_format: `Custom format deleted successfully.`,
                valid_asset_group: `Please select valid Asset Group.`,
            },
        },
        validation: {
            required: (obj) => `${obj.field} is required.`,
            greater_than: (obj) => `Must be greater than ${obj.greaterValue}.`,
            lesser_than: (obj) => `Must be less than ${obj.lesserValue}.`,
            greater_equal: (obj) => `Must be greater than or equal to ${obj.greaterValue}.`,
            lesser_equal: (obj) => `Must be less than or equal to ${obj.lesserValue}.`,
            min_max: (obj) => `Must be between ${obj.lesserValue} to ${obj.greaterValue}.`,
            before: (obj) => `Must be before ${obj.beforeField}.`,
            decimal_precision: (obj) => `Must be a positive number.`,
            depreciation_start_date_min: `Must be on or after Book Beginning Date.`,
            expense_bill_date_min: `Must be on or after Book Beginning Date.`,
            expense_bill_due_date_min: `Must be on or after Bill Date.`,
            depreciation_start_date_max: `Must be before Book Beginning Date.`,
            valid: (obj) => `please enter valid ${obj.field}`,
            asset_group_name_invalid: `Only alphanumeric and ()/\\\_- allowed.`,
        },
        depreciation_schedule_grid: {
            heading: `Depreciation Schedule`,
            financial_year: `Financial Year`,
            search_field: `Search Records`,
            btn_rollback: `Roll Back`,
            btn_post_depreciation: `Post Depreciation`,
            btn_all_asset_group: `All Asset Groups`,
            no_records: `No records.`,
            column: {
                asset_name: `Asset Name`,
                jan: `Jan`,
                feb: `Feb`,
                mar: `Mar`,
                apr: `Apr`,
                may: `May`,
                jun: 'Jun',
                jul: `Jul`,
                aug: `Aug`,
                sep: `Sep`,
                oct: `Oct`,
                nov: `Nov`,
                dec: `Dec`,
                total: `Total`,
            },
            messages: {
                try_again: `Please try again later!`,
                delete_success_format: `Custom format deleted successfully.`,
            },
            ttip_posted: (obj) => `${obj.posted ? 'Posted' : 'Not Posted'}`,
        },
        depreciation_dialog: {
            title_post: `Post Summary`,
            body_post: `There is no depreciation to be posted in the selected period.`,
            title_rollback: `Roll Back Summary`,
            body_rollback: `There is no depreciation to be rolled back in the selected period.`,
            btn_primary: `Got it`,
        },
        info_dialog_post_preview: {
            title: `Post Summary`,
            body: (obj) => `${obj.count} depreciation Journal ${obj.count == 1 ? 'Entry ' : 'Entries '}
            have been posted for the selected period.`,
            empty_body: `There is no depreciation to be posted in the selected period.`,
            btn_primary: `Got it`,
        },
        dialog_post_preview: {
            title: `Post Preview`,
            body: `The following Depreciation Journal Entries will be posted:`,
            btn_primary: `Post`,
            btn_secondary: `Cancel`,
            column: {
                asset_group: `Asset Group`,
                account: `Account`,
                debit: `Dr`,
                credit: `Cr`,
            }
        },
        dialog_rollback_preview: {
            title: `Roll Back Preview`,
            body: `The following Depreciation Journal Entries will be rolled back:`,
            btn_primary: `Roll Back`,
            btn_secondary: `Cancel`,
            column: {
                asset_group: `Asset Group`,
                journal_entry: `Journal Entry`,
            },
        },
        info_dialog_rollback_preview: {
            title: `Roll Back Summary`,
            body: (obj) => `${obj.count} depreciation Journal  ${obj.count == 1 ? 'Entry ' : 'Entries '}
                    have been rolled back for the selected period.`,
            empty_body: `There is no depreciation to be rolled back in the selected period.`,
            btn_primary: `Got it`,
        },
    },

};
