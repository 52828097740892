import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth-guard.service';
import { environment } from '../environments/environment';

const routes: Routes = [{
  path: 'pages',
  loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule),
},
{
  path: 'auth',
  loadChildren: './auth/auth.module#customizeAuthModule',
},
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
