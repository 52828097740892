export const API_URLS = {
    LOGINURL: 'v1/iam/auth/sign-in/web',
    COOKIE: 'api-dev.deskera.xyz',
    SSO_LOGGED_IN_STATUS_URL: 'v1/iam/auth/sign-in/login/status',
    SSO_LOGOUT_URL: 'v1/iam/auth/logout',
    ORGANIZATION: {
        CONNECT: `v1/asset-integration/deskera/redirecturl`,
    },
    ASSET_GROUP: {
        GET: `v1/asset-integration/group`,
        SAVE: `v1/asset-integration/group`,
        UPDATE: (id) => `v1/asset-integration/group/${id}`,
        STATUS: (id) => `v1/asset-integration/group/status/${id}`,
        DELETE: (id) => `v1/asset-integration/group/${id}`,
    },
    PURCHASE_INVOICE: {
        GET_BY_CODE: (countryContext, code) => `v1/invoices/purchase${countryContext}/${code}`,
        CREATE: (countryContext) => `v1/invoices/purchase${countryContext}`,
        UPDATE: (countryContext, id) => `v1/invoices/purchase${countryContext}/${id}`,
        DELETE: (countryContext, id) => `v1/invoices/purchase${countryContext}/${id}`
    },
    FIXED_ASSET: {
        GET: `v1/asset-integration`,
        SAVE: `v1/asset-integration`,
        UPDATE: (id) => `v1/asset-integration/${id}`,
        DELETE: (id) => `v1/asset-integration/${id}`,
        NBVALUE: `v1/asset-integration/summary/nbv`,
    },
    ACCOUNT: {
        GETNAMES: `v1/account/accountgroup/names`,
    },
    TENANT: {
        GET: (id) => `v1/tenants/${id}`,
    },
    CONTACT: {
        GET: (countryContext) => `v1/contacts${countryContext}`,
    },
    SEQUENCE_FORMAT: {
        FETCH_ALL: 'v1/sequenceformat/fetchActive',
        SAVE: 'v1/sequenceformat/save',
        DELETE: 'v1/sequenceformat/delete',
        TOGGLE_DEFAULT: 'v1/sequenceformat/toggleIsDefault',
        SEQUENCE_EXISTS: 'v1/sequenceformat/doesExist',
      },
    DEPRECIATION: {
        SCHEDULE: 'v1/asset-integration/depreciation-schedule',
        FISCAL_PERIOD: 'v1/asset-integration/depreciation-schedule/fiscal-period',
        POST_PREVIEW: 'v1/asset-integration/depreciation-schedule/post-preview',
        POST: 'v1/asset-integration/depreciation-schedule/post',
        ROLLBACK_PREVIEW: 'v1/asset-integration/depreciation-schedule/rollback-preview',
        ROLLBACK: 'v1/asset-integration/depreciation-schedule/rollback',
    },
};
