import { Injectable } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LocalStorageConstants } from '../authentication.constants';
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from 'jwt-decode';
import { API_URLS } from '../../common-utils/api-url';
import { TenantService } from '../../service/books/tenant.service';
import { ITenants } from '../../models/tenant';
import * as _moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    userLoggedInSubject = new Subject<any>();
    userLoggedInObservable: Observable<any>;
    tenantDetails: Subject<boolean> = new Subject<boolean>();

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private router: Router,
        private tenantService: TenantService,
    ) {
        this.userLoggedInObservable = this.userLoggedInSubject.asObservable();
    }

    signOut() {
        this.http.get(environment.API_URL + API_URLS.SSO_LOGOUT_URL, { withCredentials: true }).subscribe(
            (response) => {
                this.clearSession();
                this.userLoggedInSubject.next({ isLoggedIn: false });
            },
            (error) => {
                this.clearSession();
                this.userLoggedInSubject.next({ isLoggedIn: false });
            },
        );
    }
    isUserAuthenticated() {
        this.http.get(environment.API_URL + API_URLS.SSO_LOGGED_IN_STATUS_URL).subscribe(
            (response) => {
                if (response) {
                    this.setAuthStorageData(response);
                    this.setCookies();
                } else {
                    this.clearSession();
                    this.userLoggedInSubject.next({ isLoggedIn: false });
                }
            },
            (error) => {
                this.clearSession();
                this.userLoggedInSubject.next({ isLoggedIn: false });
            }
        );
    }

    setAuthStorageData(data) {
        const userDetails = jwt_decode(data.idToken);
        localStorage.setItem(LocalStorageConstants.TENANT_ID, userDetails['tenantId']);
        localStorage.setItem(LocalStorageConstants.TENANT_NAME, userDetails['website']);
        localStorage.setItem(LocalStorageConstants.EMAIL, userDetails['email']);
        localStorage.setItem(LocalStorageConstants.CONTACT, userDetails['phone_number']);
        localStorage.setItem(LocalStorageConstants.USER_NAME, userDetails['user_name']);
        localStorage.setItem(LocalStorageConstants.FULL_NAME, userDetails['name']);

        localStorage.setItem(LocalStorageConstants.ACCESS_TOKEN, data['accessToken']);
        localStorage.setItem(LocalStorageConstants.REFRESH_TOKEN, data['refreshToken']);
        localStorage.setItem(LocalStorageConstants.ID_TOKEN, data['idToken']);
        localStorage.setItem(LocalStorageConstants.EXPIRES_IN, data['expiresIn']);
        localStorage.setItem(LocalStorageConstants.TOKEN_TYPE, data['tokenType']);
        this.setTenantDetails();
    }

    setCookies() {
        this.cookieService.set('project', this.router.url, 3600, '/', environment.COOKIE);
    }

    clearSession() {
        localStorage.clear();
        this.cookieService.deleteAll('/', environment.COOKIE);
        this.cookieService.deleteAll('/', window.location.hostname);
    }
    setTenantDetails() {
        this.tenantService.get().subscribe((res: ITenants) => {
            localStorage.country = res.country;
            localStorage.currency = res.currency;
            localStorage.bookBeginningStartDate = res.bookBeginningStartDate ? res.bookBeginningStartDate : _moment().startOf('year').format('YYYY-DD-MM');
            localStorage.financialStartDate = res.financialStartDate ? res.financialStartDate : _moment().startOf('year').format('YYYY-DD-MM');
            localStorage.setItem(LocalStorageConstants.TENANT_ID, res['id'].toString());
            this.tenantDetails.next(true);
        });
    }
}
