import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/services/authentication.service';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService) {}
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(
                request.clone({
                    setHeaders: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': 'true',
                    },
                    withCredentials: true,
                })
            )
            .pipe(catchError((err: HttpErrorResponse) => {
                if (err['status'] == 401) {
                    this.authService.signOut();
                }
                return throwError(err);
            }));
    }
}
