import { Injectable } from '@angular/core';
import { ITenants } from '../../models/tenant';
import { HttpClient } from '@angular/common/http';
import { environment as env} from '../../../environments/environment';
import { API_URLS } from '../../common-utils/api-url';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private httpClient: HttpClient) {
  }

  get(id?: number) {
    id = id ? id : parseInt(localStorage.tenantId);
    return this.httpClient.get(env.API_URL + API_URLS.TENANT.GET(id));
  }
}
