
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication/services/authentication.service';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './common-utils/i18n/en';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  private unsubscribeAll: Subject<any>;
  constructor(
    private authenticationService: AuthenticationService,
    private _translate: TranslateService,
  ) {
    _translate.addLangs(['en']);
    _translate.setDefaultLang('en');
    _translate.setTranslation(english.lang, english.data, true);
    _translate.use('en');
  }

  ngOnInit(): void {
    this.authenticationService.isUserAuthenticated();
    this.authenticationService.userLoggedInObservable.subscribe((status) => {
      this.navigateToSignInOrDashboard(!!status.isLoggedIn);
    });
  }

  navigateToSignInOrDashboard(isLoggedIn: boolean) {
    if (isLoggedIn) {
        this.isLoggedIn = isLoggedIn;
    } else {
        window.location.href = environment.SSO_AUTH_URL + window.location.href;
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
