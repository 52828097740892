import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	HostListener,
	AfterViewInit,
} from '@angular/core';
import { DTMenu } from './dt-menu.interface';

@Component({
	selector: 'dt-menu',
	templateUrl: './dt-menu.component.html',
	styleUrls: ['./dt-menu.component.scss'],
})
export class DtMenuComponent implements OnInit, AfterViewInit {
	@Input() title: string;
	@Input() settingsConfig: DTMenu[];

	@Output() onItemClick = new EventEmitter();
	collapsed: boolean = false;
	doneAnimation: boolean = true;

	constructor() {}

	ngOnInit() {}
	ngAfterViewInit() {
		window.dispatchEvent(new Event('resize'));
	}

	@HostListener('window:resize', ['$event'])
	detectScreenResize(event) {
		if (event.target.outerWidth <= 1326 && !this.collapsed) {
			this.collapse();
		} else if (event.target.outerWidth > 1326 && this.collapsed) {
			this.collapse();
		}
	}
	collapse() {
		this.collapsed = !this.collapsed;
		this.doneAnimation = false;
		setTimeout(() => {
			this.doneAnimation = true;
		}, 200);
	}

	onClick(menuItem) {
		this.onItemClick.emit(menuItem);
	}
}
